<template>
  <div>

    <div class="info-wrap">
      <div class="info-row"
        style="text-align: center;">와, 멋진 서비스네요.</div>
      <div class="info-row"
        style="display: flex; justify-content: center; flex-wrap: wrap;">
        <span class="template-bold strong-word"
          >{{ value.name }}</span>
        <span class="desc-word-span">대표님이</span>
        <span class="desc-word-span">갖게 될 대표님만의 서비스 화면이예요.</span>
        <span>미리 보시죠!</span>
      </div>
    </div>

    <div class="info-wrap">
<!--
      <carousel-3d>
        <slide v-for="(slide, i) in slides" :index="i" :key="i">
          <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
            <img :data-index="index" :class="{ current: isCurrent, onLeft:afterChange (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="slide.src">
          </template>
        </slide>
      </carousel-3d> -->

      <carousel-3d class="pc"
          @after-slide-change="onAfterSlideChange"
          :width="360"
          :height="640">
        <slide
          v-for="(item,idx) in slideList"
          :key="`slide${idx}`"
          :index="idx"
          :style="`backgroundImage: url(${item.image}); backgroundSize:'contain';`"
          >
        </slide>
      </carousel-3d>

      <slick v-show="device==='mobile'"
        ref="slick" :options="slickOptions" id="slick-images"
        @afterChange="onAfterSlideChangeSlick">
        <div v-for="(img, idx) in slideList"
             :key="'slide-'+idx"
             class="slick-img-wrap">
          <img :src="img.image" alt="" class="slick-img">
        </div>
      </slick>

      <template v-for="(item,idx) in slideList">
        <div class="selected-slider-name"
          v-if="idx===slideIndex"
          :key="`name-${idx}`">{{ item.name }}</div>
      </template>

    </div>

    <div class="info-wrap">
      <div class="info-row">
        이 서비스가
        <dropdown
          label_name="label"
          value_name="value"
          :value.sync="value.type"
          :options="priceOption"
          :width="240"
          :mobileStyle="{ width: '180px', margin: '0 8px'}"
        ></dropdown>이면 바로 대표님의 서비스가 됩니다!
      </div>
      <div class="info-row">
        총 제작 시간은 <span class="template-bold strong-word">33</span> 일 소요될 예정이예요.
      </div>
      <div class="info-tip">* 더 필요하신 내용 있으시면 신청 문의하실 때 기재하실 수 있습니다. <br>
        위의 견적은 위에 표시된 영역뿐만 아니라 관리자 페이지 파트너 페이지가 합산된 금액입니다.</div>
    </div>

    <div class="preorder-subtitle">신청정보</div>

    <div class="template-area preorder-result">
      <div class="preorder-info-summary">
        저는 사용자가
        <span class="template-bold">{{ selectedPack }}</span>
        을(를) 하면<br>

        <span class="template-bold">{{ selectedPlatform }}</span> 가
        <span class="template-bold">{{ selectedOrder }}</span> 하는 서비스를 만들고 싶어요.<br><br>

        사용자들이 주문하기전에
        <span class="template-bold">{{ selectedPlatform }}</span>
        에게 문의를 할 수 있게 상품 상세페이지에
        <span class="template-bold">{{ selectedInquiry }}</span> 가 있었으면 좋겠어요.<br>

        제 서비스에서는 결제는
        <span class="template-bold">{{ selectedPayment }}</span> 거에요.<br>

        그 밖의 기능으로 회원등급이
        <span class="template-bold">{{ selectedUserGrade }}</span> 해요.<br>

        그리고 서비스를 제공하는 사람은 등급이
        <span class="template-bold">{{ selectedPartnerGrade }}</span> 좋겠어요.<br><br>

        제가 생각하는 페이지 디자인은<br>

        메인페이지는
        <span v-if="value.main_upload[0].name" class="lp-template-item template-highlight">{{ value.main_upload[0].name }}</span>
        <span v-if="value.main_upload[1].name" class="lp-template-item template-highlight">{{ value.main_upload[1].name }}</span>
        이렇게,<br>

        상품목록 페이지는
        <span v-if="value.product_upload[0].name" class="lp-template-item template-highlight">{{ value.product_upload[0].name }}</span>
        <span v-if="value.product_upload[1].name" class="lp-template-item template-highlight">{{ value.product_upload[1].name }}</span>
        이렇게,<br>

        그리고 상품상세 페이지는
        <span v-if="value.product_detail_upload[0].name" class="lp-template-item template-highlight">{{ value.product_detail_upload[0].name }}</span>
        <span v-if="value.product_detail_upload[1].name" class="lp-template-item template-highlight">{{ value.product_detail_upload[1].name }}</span>
        이렇게,<br>

        <span v-if="value.blank_page" class="template-bold">{{ value.blank_page }}</span> 페이지는
        <span v-if="value.blank_upload[0].name" class="lp-template-item template-highlight">{{ value.blank_upload[0].name }}</span>
        <span v-if="value.blank_upload[1].name" class="lp-template-item template-highlight">{{ value.blank_upload[1].name }}</span>
        이렇게 하려고 했어요.

      </div>
    </div>
    <br>
    <br>
    <br>

    <sweet-modal ref="modal" @close="showModal=false;">
      <lp-preorder-modal
        :selectedValue="selectedValue"
        @closeModal="$refs.modal.close()"></lp-preorder-modal>
    </sweet-modal>


  </div>
</template>

<script>
  import { Carousel3d, Slide } from 'vue-carousel-3d';
  import { SweetModal } from 'sweet-modal-vue';
  import LpPreorderModal from '../modal/LpPreorderModal.vue'
  import Dropdown from '../component/Dropdown.vue'
  import {currency} from "../../filter";
  import Slick from 'vue-slick';

  export default {

    components: {
      Carousel3d,
      Slide,
      Slick,
      SweetModal,
      LpPreorderModal,
      Dropdown
    },

    props: {
      value: {
        type: Object,
        required: true
      },
      option: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        showModal: false,

        slideIndex: 0,

        vSlick: false,
        slickOptions: {
          centerMode: false,
          variableWidth: false,
          infinite: true,
          autoplay: false,
          centerPadding:'20px',
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 300,
          prevArrow: '<div class="arrow-left"><img class="arrow-img" src="/static/img/img_arrow_left.png" @click="$refs.slick.prev()"></div>',
          nextArrow: '<div class="arrow-right"><img class="arrow-img" src="/static/img/img_arrow_right.png" @click="$refs.slick.next()"></div>',
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                dots: false,
                arrows: false
              }
            }
          ]
        }
      }
    },

    watch: {
      showModal(n) {
        if (n) this.$refs.modal.open();
      }
    },

    computed: {
      device() {
        let deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        // 모바일
        if (deviceWidth<=600) {
          return 'mobile';
        }
        else return 'pc';
      },

      slideList() {
        let result = [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value.blank_upload[0].name = this.value.blank_page + ' 페이지';
        [
          this.value.main_upload[0],
          this.value.product_upload[0],
          this.value.product_detail_upload[0],
          this.value.blank_upload[0]
        ].forEach(item => {
          if(item.file !== '') {
            result.push({
              name: item.name,
              image: item.file
            })
          }
        });

        // 팩 종류
        if(this.selectedOrder === '전화연결') {
          result.push(
            {
              name: '전화연결 페이지',
              image: 'https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/homepage/call/call.png'
            },
            {
              name: '전화상담 내역 페이지',
              image: 'https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/homepage/call/mycalllist.png'
            });
        }
        else if(this.selectedOrder === '견적요청') {
          result.push(
            {
              name: '견적요청 페이지',
              image: 'https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/homepage/estimate/estimate.png'
            },
            {
              name: '견적 요청 내역 페이지',
              image: 'https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/homepage/estimate/estlist.png'
            });
        }
        else if(this.selectedOrder === '컨텐츠다운로드') {
          result.push(
            {
              name: '주문/결제 페이지',
              image: 'https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/homepage/download/order.png'
            },
            {
              name: '주문 내역 페이지',
              image: 'https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/homepage/call/orderlist.png'
            });
        }

        return result;
      },

      priceOption() {
        return [
          {
            label: '한달 ' + currency(this.monthPrice),
            value: 1
          },
          {
            label: currency(this.oncePrice),
            value: 0
          },
        ]
      },

      // 월 정기 결제 가격 계산
      monthPrice() {
        let total = 0;
        this.option.inquiry.forEach(inquiry=>{
          if(this.value.inquiry > 0 && this.value.inquiry===inquiry.id) total += this.getMonthPrice(inquiry.options);
        })

        this.option.order.forEach(order=>{
          if(this.value.order > 0 && this.value.order===order.id) total += this.getMonthPrice(order.options);
        })

        this.option.payment.forEach(payment=>{
          if(this.value.payment > 0 && this.value.payment===payment.id) total += this.getMonthPrice(payment.options);
        })

        // 플러그인 예외 type === 0: 1회결제, type === 1: 월가격
        if(this.value.userGrade===1){
          this.option.plugIn.forEach(item=>{
            if(item.name==='회원등급') total += this.getMonthPrice(item.options);
          })
        }
        if(this.value.partnerGrade===1){
          this.option.plugIn.forEach(item=>{
            if(item.name==='파트너등급') total += this.getMonthPrice(item.options);
          })
        }
        return total;
      },
      // 1회 결제 가격 계산
      oncePrice() {
        let total = 0;
        this.option.inquiry.forEach(inquiry=>{
          if(this.value.inquiry > 0 && this.value.inquiry===inquiry.id) total += this.getOncePrice(inquiry.options);
        })

        this.option.order.forEach(order=>{
          if(this.value.order > 0 && this.value.order===order.id) total += this.getOncePrice(order.options);
        })

        this.option.payment.forEach(payment=>{
          if(this.value.payment > 0 && this.value.payment===payment.id) total += this.getOncePrice(payment.options);
        })

        // 플러그인 예외 type === 0: 1회결제, type === 1: 월가격
        if(this.value.userGrade===1){
          this.option.plugIn.forEach(item=>{
            if(item.name==='회원등급') total += this.getOncePrice(item.options);
          })
        }
        if(this.value.partnerGrade===1){
          this.option.plugIn.forEach(item=>{
            if(item.name==='파트너등급') total += this.getOncePrice(item.options);
          })
        }
        return total;
      },

      // 선택된 전체 값
      selectedValue() {
        return {
          name: this.value.name,
          selectedPack: this.selectedPack,
          selectedPlatform: this.selectedPlatform,
          selectedOrder: this.selectedOrder,
          selectedInquiry: this.selectedInquiry,
          selectedPayment: this.selectedPayment,
          selectedUserGrade: this.selectedUserGrade,
          selectedPartnerGrade: this.selectedPartnerGrade,
          main_upload: this.value.main_upload,
          product_upload: this.value.product_upload,
          product_detail_upload: this.value.product_detail_upload,
          blank_upload: this.value.blank_upload,
          blank_page: this.value.blank_page,
          pay_type: this.value.type,
          price: this.value.type === 0 ? this.oncePrice : this.monthPrice,
          order_conversion_type: this.value.order_conversion_type,
          inquiry_conversion_type: this.value.inquiry_conversion_type,
          payment_type: this.value.payment_type,
          plugins: this.value.plugins,
          package: this.value.package,
          products: this.value.products
        }
      },

      // 선택된 플랫폼 주체 타입
      selectedPlatform() {
        let name = '';
        for(let i=0; i<this.option.platform.length; i++) {
          if (this.option.platform[i].id === this.value.platform) {
            name = this.option.platform[i].name;
          }
        }
        return name;
      },

      // 선택된 주문전환 타입
      selectedOrder() {
        let name = '';
        for(let i=0; i<this.option.order.length; i++) {
          if (this.option.order[i].id === this.value.order) {
            name = this.option.order[i].name;
          }
        }
        return name;
      },
       // 선택된 주문전환 타입
      selectedPack() {
        let name = '';
        for(let i=0; i<this.option.order.length; i++) {
          if (this.option.order[i].id === this.value.order) {
            name =this.option.order[i].text;
          }
        }
        return name;
      },


      selectedInquiry() {
        let name = '';
        for(let i=0; i<this.option.inquiry.length; i++) {
          if (this.option.inquiry[i].id === this.value.inquiry) {
            name = this.option.inquiry[i].name;
          }
        }
        return name;
      },

      selectedPayment() {
        let name = '';
        for(let i=0; i<this.option.payment.length; i++) {
          if (this.option.payment[i].id === this.value.payment) {
            name = this.option.payment[i].name;
          }
        }
        return name;
      },

      selectedUserGrade() {
        let name = '';
        for(let i=0; i<this.option.userGrade.length; i++) {
          if (this.option.userGrade[i].id === this.value.userGrade) {
            name = this.option.userGrade[i].name;
          }
        }
        return name;
      },
      selectedPartnerGrade() {
        let name = '';
        for(let i=0; i<this.option.partnerGrade.length; i++) {
          if (this.option.partnerGrade[i].id === this.value.partnerGrade) {
            name = this.option.partnerGrade[i].name;
          }
        }
        return name;
      }
    },

    methods: {
      // type === 0 : 1회결제, type === 1 : 한달
      getMonthPrice(options) {
        //console.log(options)
        let price = 0;
        options.forEach(option=>{
          if(option.type===1) {
            price = option.price;
          }
        })
        return price;
      },
      // type === 0 : 1회결제
      getOncePrice(options) {
        //console.log(options)
        let price = 0;
        options.forEach(option=>{
          if(option.type===0) {
            price = option.price;
          }
        })
        return price;
      },

      // 슬라이드 (carousel 3d)
      onAfterSlideChange(index) {
        this.slideIndex = index;
      },

      // 슬라이드 - 모바일 (slick)
      onAfterSlideChangeSlick(slick, currentSlide) {
        console.log(currentSlide.currentSlide);
        this.slideIndex = currentSlide.currentSlide;
      },
    }


  }
</script>

<style scoped>

  @media (max-width: 600px) {
    .desc-word-span {
      margin-right: 4px;
    }
    .info-row {
      margin-bottom: 20px;
      line-height: 36px;
    }
  }
  @media (min-width: 601px) {
    .desc-word-span {
      margin-right: 4px;
    }
    .info-row {
      height: 60px;
    }
  }

  .preorder-page {
    font-size: 24px;
    line-height: 40px;
    font-weight: 200;
    color: #303030;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .preorder-title {
    height: 120px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .preorder-subtitle {
    margin-bottom: 16px;
    font-weight: bold;
  }

  .preorder-stepper {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
  }

  .info-wrap {
    margin-bottom: 80px;
  }

  .selected-slider-name {
    text-align: center;
    color: #c7c7c7;
    font-size: 16px;
    font-weight: 700;
  }

  .preorder-result {
    font-size: 16px;
    color: #303030;
    font-weight: 300;
  }

  .preorder-info-summary {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 20px;
  }

  .preorder-bold, .template-bold {
    font-weight: bold;
    color: #000000;
  }

  .template-highlight {
    text-decoration: underline;
    font-weight: 700;
    color: #7ad4ef;
    margin: 0 4px;
  }

  .strong-word {
    text-decoration: underline;
    margin: 0 6px;
  }

  /* Vue Carousel 3D */
  /* .carousel-3d-container {
    width: 800px;
    height: 540px !important;
  }
  .carousel-3d-slide {
    width: 280px !important;
    height: 460px !important;
    border: none;
  } */

  .slick-img-wrap {
    width: 100%;
    display: flex !important;
    justify-content: center;
  }
  .slick-img {
    /* width: 100%; */
    width: 292px;
    height: 520px;
    border: 1px solid #eee;
    padding: 0;
  }
  .info-tip {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #777;
  }
</style>
